<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="125.9px" height="30px" viewBox="0 0 125.9 30" style="enable-background:new 0 0 125.9 30;" xml:space="preserve">
<style type="text/css">
	.badge_st0{fill:none;}
	.badge_st1{fill:#F7BC60;}
	.badge_st2{fill:#FFFFFF;}
	.badge_st3{fill:#49484B;}
	.badge_st4{fill:#F26F24;}
</style>
<pattern  x="-157.1" y="427.8" width="69" height="69" patternUnits="userSpaceOnUse" id="Unnamed_Pattern" viewBox="2.1 -70.9 69 69" style="overflow:visible;">
	<g>
		<rect x="2.1" y="-70.9" class="st0" width="69" height="69"/>
		<rect x="2.1" y="-70.9" class="badge_st1" width="69" height="69"/>
		<g>
			<path class="badge_st2" d="M61.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-71.7,61.8-71.7,61.8-71.7
				C61.8-71.6,61.8-71.6,61.8-71.7"/>
			<path class="badge_st2" d="M54.1-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-71.7,54.1-71.7,54.1-71.7
				C54.1-71.6,54.1-71.6,54.1-71.7"/>
			<path class="badge_st2" d="M46.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.4-71.7,46.4-71.7,46.4-71.7
				C46.4-71.6,46.4-71.6,46.4-71.7"/>
			<path class="badge_st2" d="M38.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-71.7,38.8-71.7,38.8-71.7
				C38.8-71.6,38.8-71.6,38.8-71.7"/>
			<path class="badge_st2" d="M31.1-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-71.7,31.1-71.7,31.1-71.7
				C31.1-71.6,31.1-71.6,31.1-71.7"/>
			<path class="badge_st2" d="M23.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.4-71.7,23.4-71.7,23.4-71.7
				C23.4-71.6,23.4-71.6,23.4-71.7"/>
			<path class="badge_st2" d="M15.8-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-71.7,15.8-71.7,15.8-71.7
				C15.8-71.6,15.8-71.6,15.8-71.7"/>
			<path class="badge_st2" d="M8.1-71.7c0,0.1,0,0.1,0,0.2C8-71.4,8-71.4,7.9-71.3c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.1-71.7,8.1-71.7,8.1-71.7
				C8.1-71.6,8.1-71.6,8.1-71.7"/>
			<path class="badge_st2" d="M0.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.8-69.4,1-69,1.2-68.9c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.4-71.7,0.4-71.7,0.4-71.7
				C0.4-71.6,0.4-71.6,0.4-71.7"/>
		</g>
		<g>
			<path class="badge_st2" d="M69.4-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-71.7,69.4-71.7,69.4-71.7
				C69.4-71.6,69.4-71.6,69.4-71.7"/>
		</g>
		<path class="badge_st2" d="M0.5-71.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
			c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-69.4,1-69,1.3-68.9c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8c-0.2,0-0.3,0.1-0.4,0.2
			c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-71.7,0.5-71.7,0.5-71.7C0.5-71.6,0.5-71.6,0.5-71.7"/>
		<g>
			<g>
				<path class="badge_st2" d="M69.4-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-64,69.4-64.1,69.4-64C69.4-64,69.4-64,69.4-64"
					/>
				<path class="badge_st2" d="M61.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-64,61.8-64.1,61.8-64C61.8-64,61.8-64,61.8-64"
					/>
				<path class="badge_st2" d="M54.1-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-64,54.1-64.1,54.1-64C54.1-64,54.1-64,54.1-64"
					/>
				<path class="badge_st2" d="M46.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-64,46.5-64.1,46.5-64C46.5-64,46.5-64,46.5-64"
					/>
				<path class="badge_st2" d="M38.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-64,38.8-64.1,38.8-64C38.8-64,38.8-64,38.8-64"
					/>
				<path class="badge_st2" d="M31.1-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-64,31.1-64.1,31.1-64C31.1-64,31.1-64,31.1-64"
					/>
				<path class="badge_st2" d="M23.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-64,23.5-64.1,23.5-64C23.5-64,23.5-64,23.5-64"
					/>
				<path class="badge_st2" d="M15.8-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-64,15.8-64.1,15.8-64C15.8-64,15.8-64,15.8-64"
					/>
				<path class="badge_st2" d="M8.2-64c0,0.1,0,0.1,0,0.2C8.1-63.7,8-63.7,8-63.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-62.2,8.2-62,8.3-61.9c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-64,8.2-64.1,8.2-64C8.1-64,8.1-64,8.2-64"/>
				<path class="badge_st2" d="M0.5-64c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-62,3-61.9,3.1-62c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-64,0.5-64.1,0.5-64C0.5-64,0.5-64,0.5-64"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-56.4,69.4-56.4,69.4-56.3
					C69.4-56.3,69.4-56.3,69.4-56.3"/>
				<path class="badge_st2" d="M61.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-56.4,61.8-56.4,61.8-56.3
					C61.8-56.3,61.8-56.3,61.8-56.3"/>
				<path class="badge_st2" d="M54.1-56.3c0,0.1,0,0.1,0,0.2C54-56.1,54-56.1,53.9-56c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-56.4,54.1-56.4,54.1-56.3
					C54.1-56.3,54.1-56.3,54.1-56.3"/>
				<path class="badge_st2" d="M46.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-56.4,46.5-56.4,46.5-56.3
					C46.5-56.3,46.5-56.3,46.5-56.3"/>
				<path class="badge_st2" d="M38.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-56.4,38.8-56.4,38.8-56.3
					C38.8-56.3,38.8-56.3,38.8-56.3"/>
				<path class="badge_st2" d="M31.1-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-56.4,31.1-56.4,31.1-56.3
					C31.1-56.3,31.1-56.3,31.1-56.3"/>
				<path class="badge_st2" d="M23.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-56.4,23.5-56.4,23.5-56.3
					C23.5-56.3,23.5-56.3,23.5-56.3"/>
				<path class="badge_st2" d="M15.8-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-56.4,15.8-56.4,15.8-56.3
					C15.8-56.3,15.8-56.3,15.8-56.3"/>
				<path class="badge_st2" d="M8.2-56.3c0,0.1,0,0.1,0,0.2C8.1-56.1,8-56.1,8-56c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-56.4,8.2-56.4,8.2-56.3
					C8.1-56.3,8.1-56.3,8.2-56.3"/>
				<path class="badge_st2" d="M0.5-56.3c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-56.4,0.5-56.4,0.5-56.3
					C0.5-56.3,0.5-56.3,0.5-56.3"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-48.7,69.4-48.8,69.4-48.7
					C69.4-48.7,69.4-48.7,69.4-48.7"/>
				<path class="badge_st2" d="M61.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-48.7,61.8-48.8,61.8-48.7
					C61.8-48.7,61.8-48.7,61.8-48.7"/>
				<path class="badge_st2" d="M54.1-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-48.7,54.1-48.8,54.1-48.7
					C54.1-48.7,54.1-48.7,54.1-48.7"/>
				<path class="badge_st2" d="M46.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-48.7,46.5-48.8,46.5-48.7
					C46.5-48.7,46.5-48.7,46.5-48.7"/>
				<path class="badge_st2" d="M38.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-48.7,38.8-48.8,38.8-48.7
					C38.8-48.7,38.8-48.7,38.8-48.7"/>
				<path class="badge_st2" d="M31.1-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-48.7,31.1-48.8,31.1-48.7
					C31.1-48.7,31.1-48.7,31.1-48.7"/>
				<path class="badge_st2" d="M23.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-48.7,23.5-48.8,23.5-48.7
					C23.5-48.7,23.5-48.7,23.5-48.7"/>
				<path class="badge_st2" d="M15.8-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-48.7,15.8-48.8,15.8-48.7
					C15.8-48.7,15.8-48.7,15.8-48.7"/>
				<path class="badge_st2" d="M8.2-48.7c0,0.1,0,0.1,0,0.2C8.1-48.4,8-48.4,8-48.4c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C8.5-46.4,8.7-46,8.9-46c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-48.7,8.2-48.8,8.2-48.7
					C8.1-48.7,8.1-48.7,8.2-48.7"/>
				<path class="badge_st2" d="M0.5-48.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-46.4,1-46,1.3-46c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
					c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
					c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8c-0.2,0-0.3,0.1-0.4,0.2
					c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-48.7,0.5-48.8,0.5-48.7C0.5-48.7,0.5-48.7,0.5-48.7"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-41.1,69.4-41.1,69.4-41C69.4-41,69.4-41,69.4-41
					"/>
				<path class="badge_st2" d="M61.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-41.1,61.8-41.1,61.8-41C61.8-41,61.8-41,61.8-41
					"/>
				<path class="badge_st2" d="M54.1-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-41.1,54.1-41.1,54.1-41C54.1-41,54.1-41,54.1-41
					"/>
				<path class="badge_st2" d="M46.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-41.1,46.5-41.1,46.5-41C46.5-41,46.5-41,46.5-41
					"/>
				<path class="badge_st2" d="M38.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-41.1,38.8-41.1,38.8-41C38.8-41,38.8-41,38.8-41
					"/>
				<path class="badge_st2" d="M31.1-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-41.1,31.1-41.1,31.1-41C31.1-41,31.1-41,31.1-41
					"/>
				<path class="badge_st2" d="M23.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-41.1,23.5-41.1,23.5-41C23.5-41,23.5-41,23.5-41
					"/>
				<path class="badge_st2" d="M15.8-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-41.1,15.8-41.1,15.8-41C15.8-41,15.8-41,15.8-41
					"/>
				<path class="badge_st2" d="M8.2-41c0,0.1,0,0.1,0,0.2C8.1-40.8,8-40.8,8-40.7c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-41.1,8.2-41.1,8.2-41C8.1-41,8.1-41,8.2-41"/>
				<path class="badge_st2" d="M0.5-41c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-39,3-39,3.1-39c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-41.1,0.5-41.1,0.5-41C0.5-41,0.5-41,0.5-41"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-33.4,69.4-33.5,69.4-33.4
					C69.4-33.4,69.4-33.4,69.4-33.4"/>
				<path class="badge_st2" d="M61.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-33.4,61.8-33.5,61.8-33.4
					C61.8-33.4,61.8-33.4,61.8-33.4"/>
				<path class="badge_st2" d="M54.1-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-33.4,54.1-33.5,54.1-33.4
					C54.1-33.4,54.1-33.4,54.1-33.4"/>
				<path class="badge_st2" d="M46.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-33.4,46.5-33.5,46.5-33.4
					C46.5-33.4,46.5-33.4,46.5-33.4"/>
				<path class="badge_st2" d="M38.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-33.4,38.8-33.5,38.8-33.4
					C38.8-33.4,38.8-33.4,38.8-33.4"/>
				<path class="badge_st2" d="M31.1-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-33.4,31.1-33.5,31.1-33.4
					C31.1-33.4,31.1-33.4,31.1-33.4"/>
				<path class="badge_st2" d="M23.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-33.4,23.5-33.5,23.5-33.4
					C23.5-33.4,23.5-33.4,23.5-33.4"/>
				<path class="badge_st2" d="M15.8-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-33.4,15.8-33.5,15.8-33.4
					C15.8-33.4,15.8-33.4,15.8-33.4"/>
				<path class="badge_st2" d="M8.2-33.4c0,0.1,0,0.1,0,0.2C8.1-33.1,8-33.1,8-33.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-33.4,8.2-33.5,8.2-33.4
					C8.1-33.4,8.1-33.4,8.2-33.4"/>
				<path class="badge_st2" d="M0.5-33.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-33.4,0.5-33.5,0.5-33.4
					C0.5-33.4,0.5-33.4,0.5-33.4"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-25.8,69.4-25.8,69.4-25.7
					C69.4-25.7,69.4-25.7,69.4-25.7"/>
				<path class="badge_st2" d="M61.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-25.8,61.8-25.8,61.8-25.7
					C61.8-25.7,61.8-25.7,61.8-25.7"/>
				<path class="badge_st2" d="M54.1-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-25.8,54.1-25.8,54.1-25.7
					C54.1-25.7,54.1-25.7,54.1-25.7"/>
				<path class="badge_st2" d="M46.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-25.8,46.5-25.8,46.5-25.7
					C46.5-25.7,46.5-25.7,46.5-25.7"/>
				<path class="badge_st2" d="M38.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-25.8,38.8-25.8,38.8-25.7
					C38.8-25.7,38.8-25.7,38.8-25.7"/>
				<path class="badge_st2" d="M31.1-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-25.8,31.1-25.8,31.1-25.7
					C31.1-25.7,31.1-25.7,31.1-25.7"/>
				<path class="badge_st2" d="M23.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-25.8,23.5-25.8,23.5-25.7
					C23.5-25.7,23.5-25.7,23.5-25.7"/>
				<path class="badge_st2" d="M15.8-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-25.8,15.8-25.8,15.8-25.7
					C15.8-25.7,15.8-25.7,15.8-25.7"/>
				<path class="badge_st2" d="M8.2-25.7c0,0.1,0,0.1,0,0.2C8.1-25.4,8-25.5,8-25.4c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-24,8.2-23.8,8.3-23.6c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-25.8,8.2-25.8,8.2-25.7
					C8.1-25.7,8.1-25.7,8.2-25.7"/>
				<path class="badge_st2" d="M0.5-25.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-23.5,1-23.1,1.3-23c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-25.8,0.5-25.8,0.5-25.7
					C0.5-25.7,0.5-25.7,0.5-25.7"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-18.1,69.4-18.2,69.4-18.1
					C69.4-18,69.4-18.1,69.4-18.1"/>
				<path class="badge_st2" d="M61.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-18.1,61.8-18.2,61.8-18.1
					C61.8-18,61.8-18.1,61.8-18.1"/>
				<path class="badge_st2" d="M54.1-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-18.1,54.1-18.2,54.1-18.1
					C54.1-18,54.1-18.1,54.1-18.1"/>
				<path class="badge_st2" d="M46.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-18.1,46.5-18.2,46.5-18.1
					C46.5-18,46.5-18.1,46.5-18.1"/>
				<path class="badge_st2" d="M38.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-18.1,38.8-18.2,38.8-18.1
					C38.8-18,38.8-18.1,38.8-18.1"/>
				<path class="badge_st2" d="M31.1-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-18.1,31.1-18.2,31.1-18.1
					C31.1-18,31.1-18.1,31.1-18.1"/>
				<path class="badge_st2" d="M23.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-18.1,23.5-18.2,23.5-18.1
					C23.5-18,23.5-18.1,23.5-18.1"/>
				<path class="badge_st2" d="M15.8-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-18.1,15.8-18.2,15.8-18.1
					C15.8-18,15.8-18.1,15.8-18.1"/>
				<path class="badge_st2" d="M8.2-18.1c0,0.1,0,0.1,0,0.2C8.1-17.8,8-17.8,8-17.8c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-16.3,8.2-16.1,8.3-16c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-18.1,8.2-18.2,8.2-18.1C8.1-18,8.1-18.1,8.2-18.1
					"/>
				<path class="badge_st2" d="M0.5-18.1c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5C2.8-16.1,3-16,3.1-16c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-18.1,0.5-18.2,0.5-18.1C0.5-18,0.5-18.1,0.5-18.1
					"/>
			</g>
			<g>
				<path class="badge_st2" d="M69.4-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-10.5,69.4-10.5,69.4-10.4
					C69.4-10.4,69.4-10.4,69.4-10.4"/>
				<path class="badge_st2" d="M61.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1C63.9-8,63.8-8.2,64-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-10.5,61.8-10.5,61.8-10.4
					C61.8-10.4,61.8-10.4,61.8-10.4"/>
				<path class="badge_st2" d="M54.1-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-10.5,54.1-10.5,54.1-10.4
					C54.1-10.4,54.1-10.4,54.1-10.4"/>
				<path class="badge_st2" d="M46.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-10.5,46.5-10.5,46.5-10.4
					C46.5-10.4,46.5-10.4,46.5-10.4"/>
				<path class="badge_st2" d="M38.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1C40.9-8,40.8-8.2,41-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-10.5,38.8-10.5,38.8-10.4
					C38.8-10.4,38.8-10.4,38.8-10.4"/>
				<path class="badge_st2" d="M31.1-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-10.5,31.1-10.5,31.1-10.4
					C31.1-10.4,31.1-10.4,31.1-10.4"/>
				<path class="badge_st2" d="M23.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-10.5,23.5-10.5,23.5-10.4
					C23.5-10.4,23.5-10.4,23.5-10.4"/>
				<path class="badge_st2" d="M15.8-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1C17.9-8,17.8-8.2,18-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-10.5,15.8-10.5,15.8-10.4
					C15.8-10.4,15.8-10.4,15.8-10.4"/>
				<path class="badge_st2" d="M8.2-10.4c0,0.1,0,0.1,0,0.2C8.1-10.1,8-10.2,8-10.1C7.9-10,7.9-9.8,7.9-9.8c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-8.7,8.2-8.5,8.3-8.3c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C8.2-10.5,8.2-10.5,8.2-10.4
					C8.1-10.4,8.1-10.4,8.2-10.4"/>
				<path class="badge_st2" d="M0.5-10.4c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1C0.3-10,0.2-9.8,0.2-9.8c-0.2,0.1,0,0.2,0,0.3
					c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C0.9-8.2,1-7.8,1.3-7.7c0.2,0,0.4-0.1,0.5-0.1
					c0.2,0,0.4,0,0.6-0.1C2.6-8,2.5-8.2,2.7-8.3c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
					c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
					c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C0.5-10.5,0.5-10.5,0.5-10.4
					C0.5-10.4,0.5-10.4,0.5-10.4"/>
			</g>
		</g>
		<g>
			<path class="badge_st2" d="M69.4-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C69.8-0.5,70-0.1,70.2,0c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C69.4-2.8,69.4-2.9,69.4-2.8
				C69.4-2.7,69.4-2.8,69.4-2.8"/>
			<path class="badge_st2" d="M61.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C61.6-1,61.8-0.8,62-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C61.8-2.8,61.8-2.9,61.8-2.8
				C61.8-2.7,61.8-2.8,61.8-2.8"/>
			<path class="badge_st2" d="M54.1-2.8c0,0.1,0,0.1,0,0.2C54-2.5,54-2.5,53.9-2.5c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C54-1,54.1-0.8,54.3-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1C56.9-0.8,57-1,57.1-1.2c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C54.1-2.8,54.1-2.9,54.1-2.8
				C54.1-2.7,54.1-2.8,54.1-2.8"/>
			<path class="badge_st2" d="M46.5-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C46.8-0.5,47-0.1,47.2,0c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C46.5-2.8,46.5-2.9,46.5-2.8
				C46.5-2.7,46.5-2.8,46.5-2.8"/>
			<path class="badge_st2" d="M38.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C38.6-1,38.8-0.8,39-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C38.8-2.8,38.8-2.9,38.8-2.8
				C38.8-2.7,38.8-2.8,38.8-2.8"/>
			<path class="badge_st2" d="M31.1-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C31-1,31.1-0.8,31.3-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C31.1-2.8,31.1-2.9,31.1-2.8
				C31.1-2.7,31.1-2.8,31.1-2.8"/>
			<path class="badge_st2" d="M23.5-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.4,0.4,0.5C23.8-0.5,24-0.1,24.3,0c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C23.5-2.8,23.5-2.9,23.5-2.8
				C23.5-2.7,23.5-2.8,23.5-2.8"/>
			<path class="badge_st2" d="M15.8-2.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C15.7-1,15.8-0.8,16-0.7c0.2,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.1
				c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2
				c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8
				c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.2,0.2C15.8-2.8,15.8-2.9,15.8-2.8
				C15.8-2.7,15.8-2.8,15.8-2.8"/>
			<path class="badge_st2" d="M8.2-2.8c0,0.1,0,0.1,0,0.2C8.1-2.5,8-2.5,8-2.5C7.9-2.4,7.9-2.2,7.9-2.1c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C8-1,8.2-0.8,8.3-0.7C8.5-0.5,8.7-0.1,8.9,0c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
				c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.4-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
				c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8C9-3.5,8.8-3.4,8.7-3.4
				C8.5-3.3,8.6-3.2,8.4-3.1C8.3-3.1,8.2-3,8.2-2.9C8.2-2.8,8.2-2.9,8.2-2.8C8.1-2.7,8.1-2.8,8.2-2.8"/>
			<path class="badge_st2" d="M0.5-2.8c0,0.1,0,0.1,0,0.2C0.4-2.5,0.4-2.5,0.3-2.5C0.3-2.4,0.2-2.2,0.2-2.1c-0.2,0.1,0,0.2,0,0.3
				c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4C0.3-1,0.5-0.8,0.7-0.7C0.9-0.5,1-0.1,1.3,0c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6-0.1
				c0.2-0.1,0.1-0.3,0.3-0.5c0.1-0.1,0.3,0,0.4-0.1C3.3-0.8,3.4-1,3.5-1.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
				c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4-0.1-0.5c-0.4-0.7-1.2-0.9-2-0.8C1.3-3.5,1.2-3.4,1-3.4
				C0.9-3.3,0.9-3.2,0.7-3.1C0.6-3.1,0.5-3,0.5-2.9C0.5-2.8,0.5-2.9,0.5-2.8C0.5-2.7,0.5-2.8,0.5-2.8"/>
		</g>
	</g>
</pattern>
<g>
	<path class="badge_st3" d="M51.8,21.3h-2.2c-0.2,0-0.3-0.2-0.4-0.4l-2.6-4.4h-0.4c-0.5,0-2-0.1-2.4-0.1V21c0,0.2-0.1,0.4-0.4,0.4h-1.8
		c-0.2,0-0.4-0.2-0.4-0.4v-12c0-0.4,0.2-0.5,0.6-0.5c1-0.1,3-0.2,4.3-0.2c2.8,0,5.4,1,5.4,4.1v0.2c0,1.9-1,3-2.5,3.6L52,21
		c0,0.1,0,0.1,0,0.1C52,21.2,51.9,21.3,51.8,21.3z M49,12.3c0-1.4-1.1-2-2.9-2c-0.4,0-2.1,0-2.4,0.1v4c0.3,0,2.1,0.1,2.4,0.1
		c1.9,0,2.9-0.4,2.9-2V12.3z M56.9,17.7c0,1.5,0.1,1.6,1.4,1.6H63c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.3-0.1,0.4-0.4,0.4
		c-0.6,0.1-1.8,0.3-4,0.3c-3,0-4.7,0-4.7-3.8v-5.8c0-3.8,1.6-3.8,4.7-3.8c2.2,0,3.4,0.2,4,0.3c0.3,0,0.4,0.1,0.4,0.4V10
		c0,0.2-0.2,0.4-0.4,0.4h-4.6c-1.4,0-1.4,0-1.4,1.6v1.7h5.9c0.2,0,0.4,0.2,0.4,0.4v1.3c0,0.2-0.2,0.4-0.4,0.4h-5.9V17.7z M71.6,21.5
		c-3,0-5.5-1.3-5.5-5.2V8.6c0-0.2,0.2-0.3,0.4-0.3h1.8c0.2,0,0.4,0.1,0.4,0.3v7.6c0,2.1,1,3.1,2.9,3.1c2,0,2.9-1,2.9-3.1V8.6
		c0-0.2,0.2-0.3,0.4-0.3h1.8c0.2,0,0.4,0.1,0.4,0.3v7.6C77.1,20.2,74.5,21.5,71.6,21.5z M89.1,10.4h-3.7V21c0,0.2-0.2,0.4-0.4,0.4
		h-1.8c-0.2,0-0.4-0.2-0.4-0.4V10.4h-3.7c-0.2,0-0.4-0.1-0.4-0.3V8.6c0-0.2,0.2-0.3,0.4-0.3h9.9c0.2,0,0.4,0.1,0.4,0.3v1.4
		C89.5,10.3,89.3,10.4,89.1,10.4z M114,21.3h-2.2c-0.2,0-0.3-0.2-0.4-0.4l-2.6-4.4h-0.4c-0.5,0-2-0.1-2.4-0.1V21
		c0,0.2-0.1,0.4-0.3,0.4h-1.8c-0.2,0-0.4-0.2-0.4-0.4v-12c0-0.4,0.2-0.5,0.6-0.5c1-0.1,3-0.2,4.3-0.2c2.8,0,5.4,1,5.4,4.1v0.2
		c0,1.9-1,3-2.5,3.6l2.9,4.9c0,0.1,0,0.1,0,0.1C114.3,21.2,114.2,21.3,114,21.3z M111.3,12.3c0-1.4-1.1-2-2.9-2
		c-0.4,0-2.1,0-2.4,0.1v4c0.3,0,2.1,0.1,2.4,0.1c1.9,0,2.9-0.4,2.9-2V12.3z M121,21.5c-1.6,0-3.3-0.3-4.1-0.5
		c-0.2,0-0.3-0.2-0.3-0.4v-1.3c0-0.2,0.2-0.3,0.3-0.3c0,0,0,0,0,0c0.7,0.1,2.5,0.3,4.1,0.3c1.6,0,2.1-0.6,2.1-1.6
		c0-1.4-3.3-2.1-4.9-2.8c-1.6-0.7-2.2-1.9-2.2-3.2c0-2,1.6-3.5,5-3.5c1.8,0,3.3,0.3,4,0.5c0.2,0,0.3,0.2,0.3,0.3v1.4
		c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0c-1.2-0.1-2.5-0.3-4.1-0.3c-1.4,0-2.4,0.5-2.4,1.3c0,0.6,0.7,1.2,1.5,1.5
		c0.8,0.3,1.9,0.7,3.1,1.2c1.5,0.6,2.5,1.5,2.5,3.3C125.9,19.5,124.7,21.5,121,21.5z M93.9,17.7c0,1.5,0.1,1.6,1.4,1.6h4.6
		c0.2,0,0.4,0.2,0.4,0.4v1.2c0,0.3-0.1,0.4-0.4,0.4c-0.6,0.1-1.8,0.3-4,0.3c-3,0-4.7,0-4.7-3.8v-5.8c0-3.8,1.6-3.8,4.7-3.8
		c2.2,0,3.4,0.2,4,0.3c0.3,0,0.4,0.1,0.4,0.4V10c0,0.2-0.2,0.4-0.4,0.4h-4.6c-1.4,0-1.4,0-1.4,1.6v1.7h5.9c0.2,0,0.4,0.2,0.4,0.4
		v1.3c0,0.2-0.2,0.4-0.4,0.4h-5.9V17.7z"/>
	<g>
		<path class="badge_st4" d="M14.9,7.1c-0.2,0.3-0.6,0.3-0.9,0c-0.3-0.2-0.3-0.6,0-0.9c0.2-0.3,0.6-0.3,0.9,0C15.1,6.4,15.1,6.8,14.9,7.1z
			 M17.6,6c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0C17.9,6.8,17.9,6.3,17.6,6z M24,15.7
			c-0.2,0.3-0.2,0.7,0,0.9c0.3,0.2,0.7,0.2,0.9,0c0.2-0.3,0.2-0.7,0-0.9C24.6,15.4,24.2,15.4,24,15.7z M23.8,12.9
			c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1C24.6,12.5,24.1,12.6,23.8,12.9z M8,14.4
			c0.2-0.3,0.2-0.7,0-0.9c-0.3-0.2-0.7-0.2-0.9,0c-0.2,0.3-0.2,0.6,0,0.9C7.4,14.6,7.8,14.6,8,14.4z M8.2,17.1
			c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C7.4,17.5,7.9,17.5,8.2,17.1z M17.1,23.8
			c0.3,0.2,0.7,0.2,0.9,0c0.2-0.3,0.2-0.7,0-0.9c-0.3-0.2-0.7-0.2-0.9,0C16.9,23.2,16.9,23.6,17.1,23.8z M14.3,24
			c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0C14,23.2,14,23.7,14.3,24z M12.2,9.5
			c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6c0.3,0.1,0.5-0.1,0.6-0.3C12.6,9.8,12.5,9.6,12.2,9.5z M14,8.4
			c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.7-0.1,0.8-0.4C14.5,8.8,14.3,8.5,14,8.4z M21.5,12.7
			c-0.1,0.3,0.1,0.5,0.3,0.6c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6C21.9,12.3,21.6,12.5,21.5,12.7z M20.4,10.9
			c-0.1,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8C20.9,10.4,20.5,10.6,20.4,10.9z M10.4,17.3
			c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6C10.1,17.7,10.4,17.6,10.4,17.3z M11.5,19.1
			c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3,0.1,0.7,0.4,0.8C11.1,19.6,11.4,19.4,11.5,19.1z M19.7,20.5
			c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3C19.3,20.2,19.5,20.5,19.7,20.5z M17.9,21.6
			c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.7,0.1-0.8,0.4C17.4,21.2,17.6,21.5,17.9,21.6z M11.2,14
			c-0.2,0.1-0.3,0.4-0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.2c0.2-0.1,0.3-0.4,0.2-0.6C11.6,14,11.4,13.9,11.2,14z M11.5,12.6
			c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7C12.1,12.5,11.8,12.4,11.5,12.6z M17.6,11
			c0.1,0.2,0.4,0.3,0.6,0.2c0.2-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.4-0.3-0.6-0.2C17.6,10.5,17.5,10.8,17.6,11z M16.2,10.6
			c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7c-0.1-0.2-0.4-0.3-0.7-0.2C16.1,10.1,16,10.4,16.2,10.6z M14.3,19
			c-0.1-0.2-0.4-0.3-0.6-0.2c-0.2,0.1-0.3,0.4-0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.2C14.4,19.5,14.5,19.2,14.3,19z M15.8,19.4
			c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.2C15.8,19.9,15.9,19.6,15.8,19.4z M20.8,16
			c0.2-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.4-0.3-0.6-0.2c-0.2,0.1-0.3,0.4-0.2,0.6C20.3,16,20.6,16.1,20.8,16z M20.4,17.5
			c0.2-0.1,0.3-0.4,0.2-0.7c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7C19.9,17.5,20.2,17.6,20.4,17.5z M24,2.8
			c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7c-0.1,0.5,0.2,1,0.7,1.1C23.5,3.5,23.9,3.2,24,2.8z M27.2,5
			c0.1-0.6-0.3-1.1-0.9-1.2c-0.6-0.1-1.1,0.3-1.2,0.9C25,5.3,25.4,5.8,26,5.9C26.5,6,27.1,5.6,27.2,5z M29.7,8
			c0.1-0.7-0.5-1.4-1.2-1.5c-0.7-0.1-1.4,0.5-1.5,1.2c-0.1,0.7,0.5,1.4,1.2,1.5C29,9.2,29.7,8.7,29.7,8z M23,26.6
			c-0.5,0.1-0.8,0.6-0.7,1.1c0.1,0.5,0.6,0.8,1.1,0.7c0.5-0.1,0.8-0.6,0.7-1.1C23.9,26.8,23.5,26.5,23,26.6z M26,24.1
			c-0.6,0.1-1,0.6-0.9,1.2c0.1,0.6,0.6,1,1.2,0.9c0.6-0.1,1-0.6,0.9-1.2C27.1,24.4,26.5,24,26,24.1z M28.3,20.9
			c-0.7,0.1-1.3,0.7-1.2,1.5c0.1,0.7,0.7,1.3,1.5,1.2c0.7-0.1,1.3-0.7,1.2-1.5C29.7,21.3,29,20.8,28.3,20.9z M9,3.4
			c0.5-0.1,0.8-0.6,0.7-1.1C9.6,1.9,9.1,1.6,8.6,1.7C8.1,1.8,7.8,2.3,7.9,2.8C8,3.2,8.5,3.5,9,3.4z M6,5.9c0.6-0.1,1-0.6,0.9-1.2
			c-0.1-0.6-0.6-1-1.2-0.9C5.1,3.9,4.7,4.4,4.8,5C4.8,5.6,5.4,6,6,5.9z M3.7,9.2c0.7-0.1,1.3-0.7,1.2-1.5C4.8,6.9,4.1,6.4,3.4,6.5
			C2.7,6.6,2.1,7.2,2.2,8C2.3,8.7,2.9,9.2,3.7,9.2z M7.9,27.3c-0.1,0.5,0.2,1,0.7,1.1c0.5,0.1,1-0.2,1.1-0.7c0.1-0.5-0.2-1-0.7-1.1
			C8.5,26.5,8,26.8,7.9,27.3z M4.8,25c-0.1,0.6,0.3,1.1,0.9,1.2c0.6,0.1,1.1-0.3,1.2-0.9C7,24.7,6.6,24.2,6,24.1
			C5.4,24,4.8,24.4,4.8,25z M2.2,22.1c-0.1,0.7,0.5,1.4,1.2,1.5c0.7,0.1,1.4-0.5,1.5-1.2c0.1-0.7-0.5-1.4-1.2-1.5
			C2.9,20.8,2.3,21.3,2.2,22.1z M18,3.7c-0.1-0.4-0.4-0.6-0.8-0.5c-0.4,0.1-0.6,0.4-0.5,0.8c0.1,0.4,0.4,0.6,0.8,0.5
			C17.8,4.5,18,4.1,18,3.7z M20.9,4.4c-0.1-0.5-0.6-0.7-1-0.6c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.4,0.6,0.7,1,0.6
			C20.8,5.3,21,4.8,20.9,4.4z M23.7,5.7c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3C21.8,6.9,22.4,7.2,23,7
			C23.5,6.8,23.8,6.3,23.7,5.7z M24.6,21.1c-0.3,0.2-0.4,0.7-0.2,1c0.2,0.3,0.7,0.4,1,0.2c0.3-0.2,0.4-0.7,0.2-1
			C25.3,21,24.9,20.9,24.6,21.1z M25.9,18.4c-0.4,0.2-0.5,0.8-0.3,1.2c0.2,0.4,0.8,0.5,1.2,0.3c0.4-0.2,0.5-0.8,0.3-1.2
			C26.9,18.3,26.3,18.2,25.9,18.4z M26.6,15.4c-0.5,0.3-0.7,0.9-0.4,1.4c0.3,0.5,0.9,0.7,1.4,0.4c0.5-0.3,0.7-0.9,0.4-1.4
			C27.7,15.3,27.1,15.1,26.6,15.4z M7.4,8.9c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2C6.3,8,6.2,8.4,6.4,8.7
			C6.6,9,7.1,9.1,7.4,8.9z M6,11.6c0.4-0.2,0.5-0.8,0.3-1.2C6,10,5.5,9.9,5.1,10.2c-0.4,0.2-0.5,0.8-0.3,1.2
			C5.1,11.7,5.6,11.8,6,11.6z M5.3,14.6c0.5-0.3,0.7-0.9,0.4-1.4c-0.3-0.5-0.9-0.7-1.4-0.4c-0.5,0.3-0.7,0.9-0.4,1.4
			C4.2,14.7,4.8,14.9,5.3,14.6z M14,26.3c0.1,0.4,0.4,0.6,0.8,0.5c0.4-0.1,0.6-0.4,0.5-0.8c-0.1-0.4-0.4-0.6-0.8-0.5
			C14.2,25.5,13.9,25.9,14,26.3z M11,25.6c0.1,0.5,0.6,0.7,1,0.6c0.4-0.1,0.7-0.6,0.6-1c-0.1-0.4-0.6-0.7-1-0.6
			C11.2,24.7,10.9,25.2,11,25.6z M8.3,24.3C8.4,24.9,9,25.2,9.6,25c0.6-0.2,0.9-0.8,0.7-1.3C10.1,23.1,9.5,22.8,9,23
			C8.4,23.2,8.1,23.8,8.3,24.3z M22.5,8.1c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,1.1,0,1.5c0.4,0.4,1.1,0.4,1.5,0
			C23,9.1,22.9,8.5,22.5,8.1z M20.2,6.7c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3c0.4,0.3,0.9,0.3,1.3,0
			C20.6,7.6,20.6,7,20.2,6.7z M22.8,10.3c-0.3,0.4-0.3,0.9,0,1.3c0.4,0.3,0.9,0.3,1.3,0c0.3-0.4,0.3-0.9,0-1.3
			C23.7,9.9,23.1,9.9,22.8,10.3z M10.9,21.9c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0C9,20.9,9,21.6,9.4,22
			C9.8,22.3,10.5,22.3,10.9,21.9z M9.2,19.7c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3
			C8.3,20.1,8.8,20.1,9.2,19.7z M11.7,23.4c0.4,0.3,0.9,0.3,1.3,0c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0
			C11.3,22.4,11.4,23,11.7,23.4z M18,8.1c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.4,0.1,0.9-0.1,1-0.5
			C18.7,8.7,18.4,8.2,18,8.1z M16,8c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5
			C16.6,8.5,16.4,8.1,16,8z M18.9,9.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9
			C19.4,9,19,9.2,18.9,9.5z M15,21.4c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1
			C14.4,22,14.8,21.8,15,21.4z M13,20.5c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
			C12.5,21.1,12.9,20.8,13,20.5z M16,22.1c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5
			C15.4,21.6,15.6,22,16,22.1z M13.5,10.4c-0.3,0.2-0.4,0.5-0.3,0.8c0.2,0.3,0.5,0.4,0.9,0.3c0.3-0.2,0.4-0.5,0.3-0.8
			C14.2,10.3,13.8,10.2,13.5,10.4z M12.3,11.3c-0.3,0.1-0.4,0.5-0.2,0.8c0.1,0.3,0.5,0.4,0.7,0.2c0.3-0.1,0.4-0.5,0.2-0.7
			C13,11.3,12.6,11.2,12.3,11.3z M14.7,10.7c0.1,0.3,0.5,0.4,0.8,0.2c0.3-0.1,0.4-0.5,0.2-0.8c-0.1-0.3-0.5-0.4-0.8-0.2
			C14.6,10.1,14.5,10.4,14.7,10.7z M18.7,18.8c-0.2-0.3-0.5-0.4-0.9-0.3c-0.3,0.2-0.4,0.5-0.3,0.8c0.2,0.3,0.5,0.4,0.9,0.3
			C18.8,19.5,18.9,19.1,18.7,18.8z M17.3,19.3c-0.1-0.3-0.5-0.4-0.8-0.2c-0.3,0.1-0.4,0.5-0.2,0.7c0.1,0.3,0.5,0.4,0.7,0.2
			C17.3,19.9,17.4,19.6,17.3,19.3z M19.6,18.7c0.3-0.1,0.4-0.5,0.2-0.7c-0.1-0.3-0.5-0.4-0.7-0.2c-0.3,0.1-0.4,0.5-0.2,0.7
			C19,18.7,19.3,18.8,19.6,18.7z M31.9,15c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6
			C31.2,16.6,31.9,15.9,31.9,15z M31.3,11.4c0-0.8-0.6-1.5-1.4-1.6c-0.8,0-1.5,0.6-1.6,1.4c0,0.8,0.6,1.5,1.4,1.6
			C30.6,12.8,31.3,12.2,31.3,11.4z M29.8,17.3c-0.8,0-1.4,0.7-1.4,1.6c0,0.8,0.7,1.4,1.6,1.4c0.8,0,1.4-0.7,1.4-1.6
			C31.3,17.8,30.6,17.2,29.8,17.3z M1.6,16.6c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6C0.7,13.4,0,14.1,0,15
			C0,15.9,0.7,16.6,1.6,16.6z M2.2,12.8c0.8,0,1.4-0.7,1.4-1.6c0-0.8-0.7-1.4-1.6-1.4c-0.8,0-1.4,0.7-1.4,1.6
			C0.7,12.2,1.4,12.8,2.2,12.8z M0.6,18.6c0,0.8,0.6,1.5,1.4,1.6c0.8,0,1.5-0.6,1.6-1.4c0-0.8-0.6-1.5-1.4-1.6
			C1.4,17.2,0.7,17.8,0.6,18.6z M27.4,10.1c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.3-0.9,1-0.7,1.6c0.3,0.6,1,0.9,1.6,0.7
			C27.4,11.4,27.7,10.7,27.4,10.1z M25.9,7.7C25.6,7,25,6.7,24.4,7c-0.6,0.2-0.9,0.9-0.7,1.5c0.2,0.6,0.9,0.9,1.5,0.7
			C25.8,8.9,26.1,8.2,25.9,7.7z M26.6,12.4c-0.6,0.3-0.8,1-0.5,1.5c0.3,0.6,1,0.8,1.5,0.5c0.6-0.3,0.8-1,0.5-1.5
			C27.8,12.3,27.1,12.1,26.6,12.4z M6.2,20.6c0.6-0.3,0.9-1,0.7-1.7c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.3-0.9,1-0.7,1.6
			C4.8,20.6,5.5,20.9,6.2,20.6z M5.4,17.7c0.6-0.3,0.8-1,0.5-1.5c-0.3-0.6-1-0.8-1.5-0.5c-0.6,0.3-0.8,1-0.5,1.5
			C4.1,17.7,4.8,18,5.4,17.7z M6.1,22.4C6.3,23,7,23.3,7.6,23c0.6-0.2,0.9-0.9,0.7-1.5C8,21,7.4,20.7,6.8,20.9
			C6.2,21.1,5.9,21.8,6.1,22.4z"/>
		<path class="badge_st4" d="M12.3,7.9c-0.2,0.2-0.5,0.2-0.7,0c-0.2-0.2-0.2-0.5,0-0.7C11.7,7,12,7,12.2,7.2C12.4,7.4,12.4,7.7,12.3,7.9z
			 M10,8.9c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.2-0.1,0.4,0,0.5c0.2,0.1,0.4,0.1,0.5,0C10.2,9.3,10.2,9,10,8.9z M23.3,18.3
			c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7C23.8,18.1,23.5,18.1,23.3,18.3z M21.9,20.6
			c-0.1,0.2-0.1,0.4,0,0.5c0.2,0.1,0.4,0.1,0.5,0c0.1-0.2,0.1-0.4,0-0.5C22.3,20.4,22,20.4,21.9,20.6z M8.7,11.7
			c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7C8.1,11.9,8.5,11.9,8.7,11.7z M19.7,22.8
			c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0C19.5,22.3,19.5,22.7,19.7,22.8z M10.8,11
			c-0.2-0.1-0.4,0.1-0.5,0.3c-0.1,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4-0.1,0.5-0.3C11.2,11.3,11,11.1,10.8,11z M9.9,12.9
			c-0.2,0-0.3,0-0.4,0.2c0,0.2,0,0.3,0.2,0.4c0.2,0,0.3,0,0.4-0.2C10.2,13.1,10.1,13,9.9,12.9z M22,14.7c-0.1,0.2,0.1,0.4,0.3,0.5
			c0.2,0.1,0.4-0.1,0.5-0.3c0.1-0.2-0.1-0.4-0.3-0.5C22.3,14.4,22,14.5,22,14.7z M21.8,16.7c0,0.2,0,0.3,0.2,0.4
			c0.2,0,0.3,0,0.4-0.2c0-0.2,0-0.3-0.2-0.4C22,16.5,21.9,16.6,21.8,16.7z M10,15.3c0.1-0.2-0.1-0.4-0.3-0.5
			c-0.2-0.1-0.4,0.1-0.5,0.3c-0.1,0.2,0.1,0.4,0.3,0.5C9.7,15.6,9.9,15.5,10,15.3z M21.1,19c0.2,0.1,0.4-0.1,0.5-0.3
			c0.1-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4,0.1-0.5,0.3C20.8,18.7,20.9,18.9,21.1,19z M11.2,15.5C11,15.6,11,15.8,11.1,16
			c0.1,0.2,0.3,0.2,0.5,0.1c0.2-0.1,0.3-0.3,0.2-0.5C11.6,15.5,11.4,15.4,11.2,15.5z M11.7,16.9c-0.2,0.1-0.2,0.3-0.1,0.4
			c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.2-0.3,0.1-0.4C12.1,16.9,11.9,16.9,11.7,16.9z M18.9,11.8c0.1,0.2,0.3,0.2,0.5,0.2
			c0.2-0.1,0.2-0.3,0.1-0.5c-0.1-0.2-0.3-0.2-0.5-0.1C18.8,11.4,18.8,11.6,18.9,11.8z M19.8,12.9c0.1,0.1,0.3,0.2,0.4,0.1
			c0.2-0.1,0.2-0.3,0.1-0.4c-0.1-0.2-0.3-0.2-0.4-0.1C19.8,12.6,19.7,12.8,19.8,12.9z M13.1,18.2C13,18,12.8,18,12.6,18.1
			c-0.2,0.1-0.2,0.3-0.2,0.5c0.1,0.2,0.3,0.2,0.5,0.1C13.1,18.6,13.2,18.4,13.1,18.2z M20.7,14.5c0.2-0.1,0.3-0.3,0.2-0.5
			c-0.1-0.2-0.3-0.2-0.5-0.1c-0.2,0.1-0.3,0.3-0.2,0.5C20.3,14.5,20.6,14.6,20.7,14.5z M16.6,0.6C16.6,0.3,16.3,0,16,0
			c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6C16.3,1.3,16.6,1,16.6,0.6z M16,28.7c-0.4,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.6,0.6,0.6
			c0.4,0,0.6-0.3,0.6-0.6C16.6,29,16.3,28.7,16,28.7z M19.5,28.2c-0.4,0.1-0.6,0.5-0.5,0.8c0.1,0.4,0.5,0.6,0.8,0.5
			c0.4-0.1,0.6-0.5,0.5-0.8C20.3,28.3,19.9,28.1,19.5,28.2z M12.4,28.2c-0.4-0.1-0.7,0.1-0.8,0.5c-0.1,0.4,0.1,0.7,0.5,0.8
			c0.4,0.1,0.8-0.1,0.9-0.5C13,28.7,12.8,28.3,12.4,28.2z M20.4,1.3c0.1-0.4-0.1-0.7-0.5-0.8C19.5,0.4,19.1,0.6,19,1
			c-0.1,0.4,0.1,0.8,0.5,0.8C19.9,1.9,20.3,1.7,20.4,1.3z M12.9,1c-0.1-0.4-0.5-0.6-0.9-0.5c-0.4,0.1-0.6,0.5-0.5,0.8
			c0.1,0.4,0.5,0.6,0.8,0.5C12.8,1.7,13,1.3,12.9,1z M12,4.5c-0.1-0.3-0.4-0.4-0.7-0.3c-0.3,0.1-0.4,0.4-0.3,0.7
			c0.1,0.3,0.4,0.4,0.7,0.3C12,5.1,12.1,4.8,12,4.5z M20.2,24.8C20,25,19.8,25.3,20,25.5c0.1,0.3,0.4,0.4,0.7,0.3
			c0.3-0.1,0.4-0.4,0.3-0.7C20.8,24.9,20.5,24.7,20.2,24.8z M22.6,23.4c-0.2,0.2-0.3,0.5-0.1,0.8c0.2,0.2,0.5,0.3,0.8,0.1
			c0.2-0.2,0.3-0.5,0.1-0.8C23.2,23.2,22.8,23.2,22.6,23.4z M17.5,25.6c-0.3,0-0.5,0.3-0.5,0.6c0,0.3,0.3,0.5,0.6,0.5
			c0.3,0,0.5-0.3,0.5-0.6C18.1,25.7,17.8,25.5,17.5,25.6z M14.9,3.8c0-0.3-0.3-0.5-0.6-0.5c-0.3,0-0.5,0.3-0.5,0.6
			c0,0.3,0.3,0.5,0.6,0.5C14.7,4.4,14.9,4.1,14.9,3.8z M9.4,5.9C9.3,5.6,8.9,5.6,8.7,5.8C8.4,6,8.4,6.3,8.6,6.5
			c0.2,0.2,0.5,0.3,0.8,0.1C9.6,6.4,9.6,6.1,9.4,5.9z"/>
		<path class="badge_st4" d="M14.9,7.1c-0.2,0.3-0.6,0.3-0.9,0c-0.3-0.2-0.3-0.6,0-0.9c0.2-0.3,0.6-0.3,0.9,0C15.1,6.4,15.1,6.8,14.9,7.1z
			 M17.6,6c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0C17.9,6.8,17.9,6.3,17.6,6z M24,15.7
			c-0.2,0.3-0.2,0.7,0,0.9c0.3,0.2,0.7,0.2,0.9,0c0.2-0.3,0.2-0.7,0-0.9C24.6,15.4,24.2,15.4,24,15.7z M23.8,12.9
			c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1C24.6,12.5,24.1,12.6,23.8,12.9z M8,14.4
			c0.2-0.3,0.2-0.7,0-0.9c-0.3-0.2-0.7-0.2-0.9,0c-0.2,0.3-0.2,0.6,0,0.9C7.4,14.6,7.8,14.6,8,14.4z M8.2,17.1
			c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C7.4,17.5,7.9,17.5,8.2,17.1z M17.1,23.8
			c0.3,0.2,0.7,0.2,0.9,0c0.2-0.3,0.2-0.7,0-0.9c-0.3-0.2-0.7-0.2-0.9,0C16.9,23.2,16.9,23.6,17.1,23.8z M14.3,24
			c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0C14,23.2,14,23.7,14.3,24z M12.2,9.5
			c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6c0.3,0.1,0.5-0.1,0.6-0.3C12.6,9.8,12.5,9.6,12.2,9.5z M14,8.4
			c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.7-0.1,0.8-0.4C14.5,8.8,14.3,8.5,14,8.4z M21.5,12.7
			c-0.1,0.3,0.1,0.5,0.3,0.6c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6C21.9,12.3,21.6,12.5,21.5,12.7z M20.4,10.9
			c-0.1,0.3,0.1,0.7,0.4,0.8c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8C20.9,10.4,20.5,10.6,20.4,10.9z M10.4,17.3
			c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6C10.1,17.7,10.4,17.6,10.4,17.3z M11.5,19.1
			c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3,0.1,0.7,0.4,0.8C11.1,19.6,11.4,19.4,11.5,19.1z M19.7,20.5
			c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3C19.3,20.2,19.5,20.5,19.7,20.5z M17.9,21.6
			c0.3,0.1,0.7-0.1,0.8-0.4c0.1-0.3-0.1-0.7-0.4-0.8c-0.3-0.1-0.7,0.1-0.8,0.4C17.4,21.2,17.6,21.5,17.9,21.6z M11.2,14
			c-0.2,0.1-0.3,0.4-0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.2c0.2-0.1,0.3-0.4,0.2-0.6C11.6,14,11.4,13.9,11.2,14z M11.5,12.6
			c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7C12.1,12.5,11.8,12.4,11.5,12.6z M17.6,11
			c0.1,0.2,0.4,0.3,0.6,0.2c0.2-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.4-0.3-0.6-0.2C17.6,10.5,17.5,10.8,17.6,11z M16.2,10.6
			c0.1,0.2,0.4,0.3,0.7,0.2c0.2-0.1,0.3-0.4,0.2-0.7c-0.1-0.2-0.4-0.3-0.7-0.2C16.1,10.1,16,10.4,16.2,10.6z M14.3,19
			c-0.1-0.2-0.4-0.3-0.6-0.2c-0.2,0.1-0.3,0.4-0.2,0.6c0.1,0.2,0.4,0.3,0.6,0.2C14.4,19.5,14.5,19.2,14.3,19z M15.8,19.4
			c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.2C15.8,19.9,15.9,19.6,15.8,19.4z M20.8,16
			c0.2-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.4-0.3-0.6-0.2c-0.2,0.1-0.3,0.4-0.2,0.6C20.3,16,20.6,16.1,20.8,16z M20.4,17.5
			c0.2-0.1,0.3-0.4,0.2-0.7c-0.1-0.2-0.4-0.3-0.7-0.2c-0.2,0.1-0.3,0.4-0.2,0.7C19.9,17.5,20.2,17.6,20.4,17.5z M24,2.8
			c0.1-0.5-0.2-1-0.7-1.1c-0.5-0.1-1,0.2-1.1,0.7c-0.1,0.5,0.2,1,0.7,1.1C23.5,3.5,23.9,3.2,24,2.8z M27.2,5
			c0.1-0.6-0.3-1.1-0.9-1.2c-0.6-0.1-1.1,0.3-1.2,0.9C25,5.3,25.4,5.8,26,5.9C26.5,6,27.1,5.6,27.2,5z M29.7,8
			c0.1-0.7-0.5-1.4-1.2-1.5c-0.7-0.1-1.4,0.5-1.5,1.2c-0.1,0.7,0.5,1.4,1.2,1.5C29,9.2,29.7,8.7,29.7,8z M23,26.6
			c-0.5,0.1-0.8,0.6-0.7,1.1c0.1,0.5,0.6,0.8,1.1,0.7c0.5-0.1,0.8-0.6,0.7-1.1C23.9,26.8,23.5,26.5,23,26.6z M26,24.1
			c-0.6,0.1-1,0.6-0.9,1.2c0.1,0.6,0.6,1,1.2,0.9c0.6-0.1,1-0.6,0.9-1.2C27.1,24.4,26.5,24,26,24.1z M28.3,20.9
			c-0.7,0.1-1.3,0.7-1.2,1.5c0.1,0.7,0.7,1.3,1.5,1.2c0.7-0.1,1.3-0.7,1.2-1.5C29.7,21.3,29,20.8,28.3,20.9z M9,3.4
			c0.5-0.1,0.8-0.6,0.7-1.1C9.6,1.9,9.1,1.6,8.6,1.7C8.1,1.8,7.8,2.3,7.9,2.8C8,3.2,8.5,3.5,9,3.4z M6,5.9c0.6-0.1,1-0.6,0.9-1.2
			c-0.1-0.6-0.6-1-1.2-0.9C5.1,3.9,4.7,4.4,4.8,5C4.8,5.6,5.4,6,6,5.9z M3.7,9.2c0.7-0.1,1.3-0.7,1.2-1.5C4.8,6.9,4.1,6.4,3.4,6.5
			C2.7,6.6,2.1,7.2,2.2,8C2.3,8.7,2.9,9.2,3.7,9.2z M7.9,27.3c-0.1,0.5,0.2,1,0.7,1.1c0.5,0.1,1-0.2,1.1-0.7c0.1-0.5-0.2-1-0.7-1.1
			C8.5,26.5,8,26.8,7.9,27.3z M4.8,25c-0.1,0.6,0.3,1.1,0.9,1.2c0.6,0.1,1.1-0.3,1.2-0.9C7,24.7,6.6,24.2,6,24.1
			C5.4,24,4.8,24.4,4.8,25z M2.2,22.1c-0.1,0.7,0.5,1.4,1.2,1.5c0.7,0.1,1.4-0.5,1.5-1.2c0.1-0.7-0.5-1.4-1.2-1.5
			C2.9,20.8,2.3,21.3,2.2,22.1z M18,3.7c-0.1-0.4-0.4-0.6-0.8-0.5c-0.4,0.1-0.6,0.4-0.5,0.8c0.1,0.4,0.4,0.6,0.8,0.5
			C17.8,4.5,18,4.1,18,3.7z M20.9,4.4c-0.1-0.5-0.6-0.7-1-0.6c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.4,0.6,0.7,1,0.6
			C20.8,5.3,21,4.8,20.9,4.4z M23.7,5.7c-0.2-0.6-0.8-0.9-1.3-0.7c-0.6,0.2-0.9,0.8-0.7,1.3C21.8,6.9,22.4,7.2,23,7
			C23.5,6.8,23.8,6.3,23.7,5.7z M24.6,21.1c-0.3,0.2-0.4,0.7-0.2,1c0.2,0.3,0.7,0.4,1,0.2c0.3-0.2,0.4-0.7,0.2-1
			C25.3,21,24.9,20.9,24.6,21.1z M25.9,18.4c-0.4,0.2-0.5,0.8-0.3,1.2c0.2,0.4,0.8,0.5,1.2,0.3c0.4-0.2,0.5-0.8,0.3-1.2
			C26.9,18.3,26.3,18.2,25.9,18.4z M26.6,15.4c-0.5,0.3-0.7,0.9-0.4,1.4c0.3,0.5,0.9,0.7,1.4,0.4c0.5-0.3,0.7-0.9,0.4-1.4
			C27.7,15.3,27.1,15.1,26.6,15.4z M7.4,8.9c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2C6.3,8,6.2,8.4,6.4,8.7
			C6.6,9,7.1,9.1,7.4,8.9z M6,11.6c0.4-0.2,0.5-0.8,0.3-1.2C6,10,5.5,9.9,5.1,10.2c-0.4,0.2-0.5,0.8-0.3,1.2
			C5.1,11.7,5.6,11.8,6,11.6z M5.3,14.6c0.5-0.3,0.7-0.9,0.4-1.4c-0.3-0.5-0.9-0.7-1.4-0.4c-0.5,0.3-0.7,0.9-0.4,1.4
			C4.2,14.7,4.8,14.9,5.3,14.6z M14,26.3c0.1,0.4,0.4,0.6,0.8,0.5c0.4-0.1,0.6-0.4,0.5-0.8c-0.1-0.4-0.4-0.6-0.8-0.5
			C14.2,25.5,13.9,25.9,14,26.3z M11,25.6c0.1,0.5,0.6,0.7,1,0.6c0.4-0.1,0.7-0.6,0.6-1c-0.1-0.4-0.6-0.7-1-0.6
			C11.2,24.7,10.9,25.2,11,25.6z M8.3,24.3C8.4,24.9,9,25.2,9.6,25c0.6-0.2,0.9-0.8,0.7-1.3C10.1,23.1,9.5,22.8,9,23
			C8.4,23.2,8.1,23.8,8.3,24.3z"/>
		<path class="badge_st4" d="M22.6,9.5c-0.4,0.4-1,0.4-1.5,0c-0.4-0.4-0.4-1,0-1.5c0.4-0.4,1-0.4,1.5,0C22.9,8.5,23,9.1,22.6,9.5z
			 M20.2,6.7c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3c0.4,0.3,0.9,0.3,1.3,0C20.6,7.6,20.6,7,20.2,6.7z M22.8,10.3
			c-0.3,0.4-0.3,0.9,0,1.3c0.4,0.3,0.9,0.3,1.3,0c0.3-0.4,0.3-0.9,0-1.3C23.7,9.9,23.1,9.9,22.8,10.3z M10.9,21.9
			c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0C9,20.9,9,21.6,9.4,22C9.8,22.3,10.5,22.3,10.9,21.9z M9.2,19.7
			c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0c-0.3,0.4-0.3,0.9,0,1.3C8.3,20.1,8.8,20.1,9.2,19.7z M11.7,23.4
			c0.4,0.3,0.9,0.3,1.3,0c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.3-0.9-0.3-1.3,0C11.3,22.4,11.4,23,11.7,23.4z M18,8.1
			c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.4,0.1,0.9-0.1,1-0.5C18.7,8.7,18.4,8.2,18,8.1z M16,8c-0.4-0.1-0.8,0.1-0.9,0.5
			c-0.1,0.4,0.1,0.8,0.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5C16.6,8.5,16.4,8.1,16,8z M18.9,9.5c-0.1,0.4,0.1,0.8,0.5,0.9
			c0.4,0.1,0.8-0.1,0.9-0.5c0.1-0.4-0.1-0.8-0.5-0.9C19.4,9,19,9.2,18.9,9.5z M15,21.4c0.1-0.4-0.1-0.9-0.5-1
			c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1C14.4,22,14.8,21.8,15,21.4z M13,20.5c0.1-0.4-0.1-0.8-0.5-0.9
			c-0.4-0.1-0.8,0.1-0.9,0.5c-0.1,0.4,0.1,0.8,0.5,0.9C12.5,21.1,12.9,20.8,13,20.5z M16,22.1c0.4,0.1,0.8-0.1,0.9-0.5
			c0.1-0.4-0.1-0.8-0.5-0.9c-0.4-0.1-0.8,0.1-0.9,0.5C15.4,21.6,15.6,22,16,22.1z M13.5,10.4c-0.3,0.2-0.4,0.5-0.3,0.8
			c0.2,0.3,0.5,0.4,0.9,0.3c0.3-0.2,0.4-0.5,0.3-0.8C14.2,10.3,13.8,10.2,13.5,10.4z M12.3,11.3c-0.3,0.1-0.4,0.5-0.2,0.8
			c0.1,0.3,0.5,0.4,0.7,0.2c0.3-0.1,0.4-0.5,0.2-0.7C13,11.3,12.6,11.2,12.3,11.3z M14.7,10.7c0.1,0.3,0.5,0.4,0.8,0.2
			c0.3-0.1,0.4-0.5,0.2-0.8c-0.1-0.3-0.5-0.4-0.8-0.2C14.6,10.1,14.5,10.4,14.7,10.7z M18.7,18.8c-0.2-0.3-0.5-0.4-0.9-0.3
			c-0.3,0.2-0.4,0.5-0.3,0.8c0.2,0.3,0.5,0.4,0.9,0.3C18.8,19.5,18.9,19.1,18.7,18.8z M17.3,19.3c-0.1-0.3-0.5-0.4-0.8-0.2
			c-0.3,0.1-0.4,0.5-0.2,0.7c0.1,0.3,0.5,0.4,0.7,0.2C17.3,19.9,17.4,19.6,17.3,19.3z M19.6,18.7c0.3-0.1,0.4-0.5,0.2-0.7
			c-0.1-0.3-0.5-0.4-0.7-0.2c-0.3,0.1-0.4,0.5-0.2,0.7C19,18.7,19.3,18.8,19.6,18.7z M31.9,15c0-0.9-0.7-1.6-1.6-1.6
			c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6C31.2,16.6,31.9,15.9,31.9,15z M31.3,11.4c0-0.8-0.6-1.5-1.4-1.6
			c-0.8,0-1.5,0.6-1.6,1.4c0,0.8,0.6,1.5,1.4,1.6C30.6,12.8,31.3,12.2,31.3,11.4z M29.8,17.3c-0.8,0-1.4,0.7-1.4,1.6
			c0,0.8,0.7,1.4,1.6,1.4c0.8,0,1.4-0.7,1.4-1.6C31.3,17.8,30.6,17.2,29.8,17.3z M1.6,16.6c0.9,0,1.6-0.7,1.6-1.6
			c0-0.9-0.7-1.6-1.6-1.6C0.7,13.4,0,14.1,0,15C0,15.9,0.7,16.6,1.6,16.6z M2.2,12.8c0.8,0,1.4-0.7,1.4-1.6c0-0.8-0.7-1.4-1.6-1.4
			c-0.8,0-1.4,0.7-1.4,1.6C0.7,12.2,1.4,12.8,2.2,12.8z M0.6,18.6c0,0.8,0.6,1.5,1.4,1.6c0.8,0,1.5-0.6,1.6-1.4
			c0-0.8-0.6-1.5-1.4-1.6C1.4,17.2,0.7,17.8,0.6,18.6z M27.4,10.1c-0.3-0.6-1-0.9-1.6-0.7c-0.6,0.3-0.9,1-0.7,1.6
			c0.3,0.6,1,0.9,1.6,0.7C27.4,11.4,27.7,10.7,27.4,10.1z M25.9,7.7C25.6,7,25,6.7,24.4,7c-0.6,0.2-0.9,0.9-0.7,1.5
			c0.2,0.6,0.9,0.9,1.5,0.7C25.8,8.9,26.1,8.2,25.9,7.7z M26.6,12.4c-0.6,0.3-0.8,1-0.5,1.5c0.3,0.6,1,0.8,1.5,0.5
			c0.6-0.3,0.8-1,0.5-1.5C27.8,12.3,27.1,12.1,26.6,12.4z M6.2,20.6c0.6-0.3,0.9-1,0.7-1.7c-0.3-0.6-1-0.9-1.6-0.7
			c-0.6,0.3-0.9,1-0.7,1.6C4.8,20.6,5.5,20.9,6.2,20.6z M5.4,17.7c0.6-0.3,0.8-1,0.5-1.5c-0.3-0.6-1-0.8-1.5-0.5
			c-0.6,0.3-0.8,1-0.5,1.5C4.1,17.7,4.8,18,5.4,17.7z M6.1,22.4C6.3,23,7,23.3,7.6,23c0.6-0.2,0.9-0.9,0.7-1.5
			C8,21,7.4,20.7,6.8,20.9C6.2,21.1,5.9,21.8,6.1,22.4z"/>
	</g>
</g>
</svg>
